window.LOADER = {
  count: 0, // starting with 0, because loader isn't displayed on page load
  set: (show = false) => {
    show ? window.LOADER.count++ : window.LOADER.count--
    if (window.LOADER.count < 0) { window.LOADER.count = 0 }

    const el = document.getElementById('loaderSvg')
    if (el) {
      el.classList.toggle('is-hidden', !window.LOADER.count)
    }
  },
  show: () => { window.LOADER.set(true) },
  hide: () => { window.LOADER.set() },
}
